<template>
  <v-container v-if="!loading" id="briefing" fluid tag="section">
    <!-- Overall -->
    <Header
      :Item="{
        Name: thesis.Name,
        IntroVideo: thesis.IntroVideo,
        Hero: thesis.Banner,
      }"
      :SignedNDA="signed_nda"
      :GoToOptions="go_to_options()"
      @go_to="scroll"
    />
    <InterestBanner :ThesisId="thesis.Id" />
    <!-- Overview -->
    <Overview id="overview" :Item="thesis" :signed_nda="signed_nda" />
    <!-- Highlights -->
    <Highlights
      id="highlights"
      v-if="highlights_filtered.length > 0"
      :Highlights="highlights_filtered"
    />
    <!-- Investment conditions -->
    <InvestmentConditions
      v-if="
        thesis.InvestmentConditionsIntro[$i18n.locale] &&
        thesis.InvestmentConditionsText[$i18n.locale]
      "
      id="investment-conditions"
      :Item="{
        InvestmentConditionsIntro:
          thesis.InvestmentConditionsIntro[$i18n.locale],
        InvestmentConditionsText: thesis.InvestmentConditionsText[$i18n.locale],
      }"
      :SignedNDA="signed_nda"
    />
    <!-- Files -->
    <BriefingFiles
      downloadUrl="thesisfile/download"
      id="files"
      :Files="files_filtered"
      :SignedNDA="signed_nda"
    />
    <!-- DXA Vision -->
    <DXAVision
      :ThesisId="thesis.Id"
      :MediaType="0"
      :Header="$t('dxa_vision')"
      id="dxa-vision"
    />
    <!-- Marlet Vision -->
    <MarketVision
      :ThesisId="thesis.Id"
      :MediaType="1"
      :Header="$t('market_vision')"
      id="market-vision"
    />
  </v-container>

  <v-container v-else>
    <v-progress-circular
      style="margin: 0 auto; display: block"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import InvestmentConditions from "@/components/dxa/Briefing/InvestmentConditions";
import MarketVision from "./Media";
import Overview from "@/components/dxa/Briefing/Overview";
import Header from "@/components/dxa/Briefing/Header";
import Highlights from "@/components/dxa/Briefing/Highlights";
import DXAVision from "./Media";
import InterestBanner from "./InterestBanner";
import BriefingFiles from "@/components/dxa/Briefing/Files";

export default {
  name: "ThesisDetails",

  components: {
    InvestmentConditions,
    MarketVision,
    Header,
    Highlights,
    DXAVision,
    BriefingFiles,
    Overview,
    InterestBanner,
  },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    signed_nda: false,
    thesis: null,
    user: null,
    files: [],
    files_filtered: [],
    highlights: [],
    highlights_filtered: [],
  }),

  async created() {
    this.loading = true;
    this.user = JSON.parse(localStorage.getItem("user"));

    var user_type = this.user.type;
    if (
      user_type == 1 ||
      user_type == 4 ||
      user_type == 7 ||
      this.user.signed_nda
    ) {
      this.signed_nda = true;
    }
    var thesis_id = this.$route.params.thesis_id;
    if (thesis_id) {
      this.loading = true;
      await this.get_thesis();
      await this.get_files();
      await this.get_highlights();
    } else {
      this.$router.push("/*");
    }
  },
  mounted() {},
  watch: {
    "$i18n.locale"() {
      this.filter_files();
      this.filter_highlights();
    },
  },
  computed: {},
  methods: {
    async get_highlights() {
      await this.apiService
        .getRequest(`thesishighlight/list/${this.$route.params.thesis_id}`)
        .then((resp) => {
          this.highlights = JSON.parse(resp.message);
          this.filter_highlights();
        })
        .catch((error) => {});
    },
    filter_highlights() {
      this.highlights_filtered = this.highlights;
    },
    async get_files() {
      await this.apiService
        .getRequest(`thesisfile/list`, {
          ThesisId: this.$route.params.thesis_id,
        })
        .then((resp) => {
          this.files = JSON.parse(resp.message);
          this.filter_files();
        })
        .catch((error) => {});
    },
    filter_files() {
      this.files_filtered = this.files.filter(
        (x) => this.gs.getLanguage(x.Language) === this.$i18n.locale
      );
    },
    async get_thesis() {
      await this.apiService
        .getRequest(`thesis/${this.$route.params.thesis_id}`)
        .then((resp) => {
          this.thesis = JSON.parse(resp.message);
          this.gs.get_translatable_field(
            this.thesis,
            "InvestmentConditionsIntro"
          );
          this.gs.get_translatable_field(
            this.thesis,
            "InvestmentConditionsText"
          );
          this.gs.get_translatable_field(this.thesis, "Overview");
          this.gs.get_translatable_field(this.thesis, "IntroVideo");
        })
        .catch((error) => {
          this.$toast.error(this.$t(error.body.message));
        });
      this.loading = false;
    },
    go_to_options() {
      var i;
      var all_options = [
        { title: "files", text: this.$t("files"), icon: "mdi-arrow-down" },
        { title: "overview", text: this.$t("overview") },
        { title: "highlights", text: this.$t("highlights") },
        {
          title: "investment-conditions",
          text: this.$t("investment_conditions"),
        },
        { title: "dxa-vision", text: this.$t("dxa_vision") },
        { title: "market-vision", text: this.$t("market_vision") },
      ];
      var return_list = [];
      for (i = 0; i < all_options.length; i++) {
        var elementExists = document.getElementById(all_options[i].title);
        if (elementExists) {
          return_list.push(all_options[i]);
        }
      }
      return return_list;
    },
    scroll(section) {
      const element = document.getElementById(section);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
