<template>
  <v-container
    v-if="Item.Overview"
    class="dxa-briefing-card mt-4"
    id="overall"
    fluid
    tag="section"
  >
    <div>
      <v-row>
        <v-col cols="12" class="overallColumn">
          <h4 class="dxa-briefing-section-header h4" data-test="Thesis:Overview:H4Overview">
            {{ $t("overview") }}
          </h4>
          <p
            id="OverviewText"
            class="mt-5 text overview-text"
            v-bind:class="{ 'text-expanded': expanded }"
            style="font-size: 1.2rem"
          >
            {{ Item.Overview[$i18n.locale] }}
          </p>
          <p
            style="font-size: 1.2rem"
            class="mb-0 overview-text"
            v-if="Item.Sector"
          >
            {{ `${$t("sector")}: ${Item.Sector}` }}
          </p>
          <p
            style="font-size: 1.2rem"
            class="overview-text"
            v-if="Item.Website"
          >
            {{ `${$t("site")}: ${Item.Website}` }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <div v-if="read_more_visible" class="mt-4 ml-4">
          <a
            v-if="expanded == false"
            @click="expanded = true"
            style="text-decoration: underline"
            >{{ $t("read_more") }}</a
          >
          <a v-else @click="expanded = false">{{ $t("read_less") }}</a>
        </div>
      </v-row>
    </div>
  </v-container>
</template>

<style lang="scss" scoped>
@import "@/sass/briefing.scss";
.overview-text {
  color: rgba(121, 121, 121, 1);
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.15px;
}
.text {
  display: block;
  max-height: 14rem;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
}
.text-expanded {
  max-height: 100% !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "BriefingFinancial",
  data: () => ({
    gs: new GeneralServices(),
    loading: false,
    expanded: false,
    read_more_visible: false,
  }),

  async created() {},
  mounted() {
    this.check_span_size();
  },
  computed: {},
  methods: {
    convertRemToPixels(rem) {
      return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
    },
    check_span_size() {
      var span = document.getElementById("OverviewText");
      if (span && this.Item.Overview) {
        var span_height = parseFloat(
          window.getComputedStyle(span).height.replace("px", "")
        );
        this.read_more_visible =
          span_height >= this.convertRemToPixels(25) - 0.1;
      }
    },
    site_short: function (site) {
      var return_value;
      if (site != null && site != "") {
        return_value = site.replace("www.", "");
        return_value = return_value.replace("http://", "");
        return_value = return_value.replace("https://", "");
        return return_value;
      }
    },
  },
  props: {
    Item: Object,
    SignedNDA: Boolean,
  },
};
</script>
