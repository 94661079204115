<template>
  <div v-if="user.type == UserTypeEnum.Investor && !loading">
    <div
      class="banner-bottom"
      :style="drawer && gs.isMobile() ? 'display: none' : ''"
    >
      <v-row class="text-part" cols="6" md="7">
        <p style="color: var(--primary)" v-if="activated">
          {{ $t("will_notify_thesis_opportunity") }}
        </p>
        <p v-else style="color: var(--dark)">
          {{ $t("notify_thesis_opportunity") }}
        </p>
      </v-row>
      <v-row
        class="btn-part"
        cols="6"
        md="5"
        style="justify-content: flex-end !important"
      >
        <v-switch
          v-model="activated"
          @change="manage_interest"
          inset
          class="mt-0"
          hide-details
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "InterestBanner",

  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    user: {},
    activated: false,
    loading: false,
    UserTypeEnum,
  }),
  props: {
    ThesisId: String,
  },

  async created() {
    this.loading = true;
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.type == this.UserTypeEnum.Investor) {
      await this.get_interest();
    }
  },
  mounted() {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
  methods: {
    async get_interest() {
      await this.apiService
        .getRequest("thesisinterest/get", {
          ThesisId: this.ThesisId,
          UserId: this.user.id,
        })
        .then((resp) => {
          this.activated = resp.content;
        })
        .catch((error) => {
          this.$toast.error(this.$t(error.body.message));
        });
      this.loading = false;
    },
    async manage_interest() {
      if (!this.activated) {
        await this.apiService
          .deleteRequest("thesisinterest", {
            ThesisId: this.ThesisId,
            UserId: this.user.id,
          })
          .then((resp) => {})
          .catch((error) => {
            this.$toast.error(this.$t(error.body.message));
          });
      } else {
        await this.apiService
          .postRequest("thesisinterest/add", {
            ThesisId: this.ThesisId,
            UserId: this.user.id,
          })
          .then((resp) => {})
          .catch((error) => {
            this.$toast.error(this.$t(error.body.message));
          });
      }
    },
  },
};
</script>
